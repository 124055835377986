<script setup>
import { computed } from "vue"

const emit = defineEmits(["update:checked"])

const props = defineProps({
  checked: {
    type: [Array, Boolean],
    default: false,
  },
  value: {
    default: null,
  },
})

const proxyChecked = computed({
  get() {
    return props.checked
  },

  set(val) {
    emit("update:checked", val)
  },
})
</script>

<template>
  <input
    type="checkbox"
    :value="value"
    v-model="proxyChecked"
    class="rounded border-gray-300 text-cerulean-600 shadow-sm focus:border-cerulean-300 focus:none"
  />
</template>
